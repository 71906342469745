<template>
  <div>
    <load-profile></load-profile>
    <n-search-container
      :show-header="true"
      :defaultVisible="true"
      @search="search"
      @reset="reset"
      :fields="searchFields"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
      </n-search-input>
    </n-search-container>
    <!-- Table Container Card -->
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BBadge,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
  BButton,
  BCardHeader,
  BCardBody,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NPagination from '@/components/NPagination'
import NInput from '@/components/NInput'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NTable from '@/components/NTable'
import Swal from 'sweetalert2'
import searchInputs from './searchInput'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const AbaTransactionRepository = Repository.get('checkTransaction')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BBadge,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NTable,
    NPagination,
    NSearchInput,
    NSearchContainer,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
    BButton,
    BCardHeader,
    BCardBody,
    Swal,
  },
  directives: {
    Ripple,
  },
  data() {
    this.searchFields = this.searchFields.map((field) => {
      return field
    })
    return {
      params: {},
      items: [],
      loading: false,
      total: 0,
    }
  },
  mounted() {},
  watch: {
    perPage(value) {
      this.list(1)
    },
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null
    },
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.name || !!this.$route.query.isEnable
    },
    fields() {
      let fieldKeys = tableFields.map((item) => {
        if (item.key == 'name') {
          item.sortField = `name_${this.$i18n.locale}`
        }
        return item
      })
      return fieldKeys
    },
  },
  mounted() {},

  methods: {
    search() {
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData()
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    reset() {
      this.params.search = ''
      this.$refs.search.reset()
      this.getData()
    },
    getData() {
      this.loading = true
      console.log(this.params.tran_id)
      if (this.params.tran_id) {
        AbaTransactionRepository.abaCallback({
          tran_id: this.params.tran_id,
          status: 0,
        })
          .then((response) => {
            const data = response.data

            if (data.success === '0') {
              Swal.fire({
                title: 'Failed',
                icon: 'error',
                text: data.message,
                customClass: {
                  confirmButton: 'btn btn-danger',
                },
                buttonsStyling: true,
              })
            } else if (data.success === '1') {
              Swal.fire({
                title: 'Success',
                icon: 'success',
                text: data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: true,
              })
            }
          })
          .catch(() => {
            this.items = []
          })
          .finally(() => {
            this.loading = false
          })
      } else {
        this.loading = false
        this.items = []
      }
    },
  },
  setup() {
    const searchFields = [...searchInputs]

    return { searchFields }
  },
}
</script>
